import { Link } from 'react-router-dom';
import styles from './FooterCampaign.module.sass';

const currentYear = (new Date()).getFullYear().toString();

const footerCampaign = () => (
  <div className={styles.FooterCampaign}>
    <div className={styles.Policities}>
      <div className={styles.Year}>2015 - {currentYear} © Carways</div>
      <div className={styles.Link}><Link to="/politica-de-privacidad">Política de Privacidad</Link></div>
      <div className={styles.Link}><Link to="/politica-de-cookies">Política de Cookies</Link></div>
      <div className={styles.Link}><Link to="/aviso-legal">Aviso Legal</Link></div>
    </div>
  </div>
);

export default footerCampaign;
