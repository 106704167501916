import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import styles from './Brands.module.sass';

import audi from './audi.png';
import bmw from './bmw.png';
import mercedes from './mercedes.png';
import porsche from './porsche.png';
import volkswagen from './volkswagen.png';

const brands = () => (
  <div className={styles.Brands}>
    <h2>Marcas TOP de nuestro concesionario de Barcelona</h2>
    <div className={styles.Logos}>
      <div className={styles.Logo}>
        <Link className={styles.Link} to='/audi/segunda-mano-barcelona'>
          <div className={styles.Brand}>
            <div className={styles.Img}><LazyLoadImage className={styles.Horizontal} src={audi} alt='Audi segunda mano'/></div>
            <h3>Audi</h3>
          </div>
        </Link>
      </div>
      <div className={styles.Logo}>
        <Link className={styles.Link} to='/bmw/segunda-mano-barcelona'>
          <div className={styles.Brand}>
            <div className={styles.Img}><LazyLoadImage src={bmw} alt='BMW segunda mano'/></div>
            <h3>BMW</h3>
          </div>
        </Link>
      </div>
      <div className={styles.Logo}>
        <Link className={styles.Link} to='/mercedes/segunda-mano-barcelona'>
          <div className={styles.Brand}>
            <div className={styles.Img}><LazyLoadImage src={mercedes} alt='Mercedes segunda mano'/></div>
            <h3>Mercedes</h3>
          </div>
        </Link>
      </div>
      <div className={styles.Logo}>
        <Link className={styles.Link} to='/porsche/segunda-mano-barcelona'>
          <div className={styles.Brand}>
            <div className={styles.Img}><LazyLoadImage src={porsche} alt='Porsche segunda mano'/></div>
            <h3>Porsche</h3>
          </div>
        </Link>
      </div>
      <div className={styles.Logo}>
        <Link className={styles.Link} to='/volkswagen/segunda-mano-barcelona'>
          <div className={styles.Brand}>
            <div className={styles.Img}><LazyLoadImage src={volkswagen} alt='Volkswagen segunda mano'/></div>
            <h3>Volkswagen</h3>
          </div>
        </Link>
      </div>
    </div>
    <div className={styles.Text}>
      <h2>Marcas TOP de nuestro concesionario de Barcelona</h2>
      En Carways Mobility vendemos las mejores marcas y modelos del mercado, basándonos en su relación calidad precio,
      fiabilidad y la opinión de profesionales y usuarios.
      <br /><br />
      Nuestras marcas de coches predilectas, sobre todo, son Audi, Mercedes, BMW, Volkswagen, Seat, Peugeot, Toyota, Hyuandai, Nissan y Kia.
    </div>
  </div>
);

export default brands;
