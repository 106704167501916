import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faDoorOpen, faHandshake } from '@fortawesome/free-solid-svg-icons';

import styles from './NoTimeWaste.module.sass';

const noTimeWaste = () => (
  <div className={styles.NoTimeWaste}>
    <div className={styles.Filter}>
      <div className={styles.Text}>
        No pierdas tiempo
        <br />
        en desplazamientos
        <br />
        <br />
        Cómpralo online
        <br />
        y te lo llevamos
      </div>
    </div>
    <div className={styles.Icons}>
      <div className={styles.Icon}>
        <FontAwesomeIcon icon={faCalendar} />
        <span>15 días o 500 km para tomar una decisión</span>
      </div>
      <div className={styles.Icon}>
        <FontAwesomeIcon icon={faDoorOpen} />
        <span>En la puerta de tu casa en 72 horas</span>
      </div>
      <div className={styles.Icon}>
        <FontAwesomeIcon icon={faHandshake} />
        <span>Trámites 100% telemáticos</span>
      </div>
    </div>
  </div>
);

export default noTimeWaste;
