import styles from './LowPrices.module.sass';

const lowPrices = () => (
  <div className={styles.LowPrices}>
    <div className={styles.Filter}>
      <div className={styles.Text}>
        Política de <u>precios bajos</u>
        <br />
        todo el año
      </div>
    </div>
  </div>
);

export default lowPrices;
