import React, { Component } from 'react';
import { withCookies } from 'react-cookie';

import styles from './CookiesDisc.module.sass';

class CookiesDisc extends Component {
  state = {
    accepted: false
  }

  componentDidMount() {
    const { cookies } = this.props;
    if(cookies.get('carways_cookies')){
      this.setState({
        accepted: true
      });
    };
  }

  handleButton = () => {
    const { cookies } = this.props;
    this.setState({
      accepted: true
    });
    cookies.set('carways_cookies', true);
  }

  render(){
    let cookiesBlock = (
      <div className={styles.Cookies}>
        Utilizamos cookies propias para mejorar y personalizar su experiencia durante la navegación. Si <a href='/politica-de-cookies' target='_blank' rel="noopener noreferrer">acepta</a>, consideramos que admite su uso.
        <button onClick={() => this.handleButton()}>Aceptar</button>
      </div>
    );
    if(this.state.accepted){ cookiesBlock = null };
    return cookiesBlock;
  }
}

export default withCookies(CookiesDisc);
