import React, { Component } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import SearchInput from './SearchInput/SearchInput';
import Aux from '../../../hoc/Aux/Aux';
import { withRouter } from 'react-router-dom';

import styles from './HomeDisclaimer.module.sass';

class HomeDisclaimer extends Component{

  state = {
    searchInput: ''
  }

  handleInput = (e) => {
    this.setState({
      searchInput: e.target.value
    });
  }

  handleInputEnter = (e) => {
    if(e.key === 'Enter' || e === 'Clicked') {
      this.props.history.push(`/coches-segunda-mano-barcelona?search=${this.state.searchInput}`);
    }
  }

  render() {
    return(
      <Aux>
        <Jumbotron className={styles.Jumbotron}>
          <div className={styles.Filter}>
            <h1>Concesionario de coches de segunda mano en Barcelona</h1>
            <p className={styles.SubTitle}>
              Disfruta de tu coche 15 días o 500 kilómetros y si no te convence
              <br />
              te lo cambiamos por otro o te devolvemos tu dinero
            </p>
            <SearchInput handleInput={this.handleInput} handleInputEnter={this.handleInputEnter}/>
          </div>
        </Jumbotron>
      </Aux>
    );
  }
}

export default withRouter(HomeDisclaimer);
