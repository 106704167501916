import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCar, faSearch, faEuroSign, faPercentage, faChartLine } from '@fortawesome/free-solid-svg-icons';

import styles from './Reasons.module.sass';

const reasons = () => (
  <div className={styles.Reasons}>
    <div className={styles.Filter}>
      <div className={styles.Icons}>
        <div className={styles.Icon}>
          <FontAwesomeIcon icon={faCalendar} />
          <span>Te acompañamos durante 12 meses después de comprar el coche</span>
        </div>
        <div className={styles.Icon}>
          <FontAwesomeIcon icon={faCar} />
          <span>Puedes cambiar el coche por otro de nuestro stock si no te convence</span>
        </div>
        <div className={styles.Icon}>
          <FontAwesomeIcon icon={faSearch} />
          <span>Revisamos los coches antes de comprarlos como si fueran para nosotros</span>
        </div>
        <div className={styles.Icon}>
          <FontAwesomeIcon icon={faEuroSign} />
          <span>Ofrecemos el mejor precio de mercado que podemos permitirnos</span>
        </div>
        <div className={styles.Icon}>
          <FontAwesomeIcon icon={faPercentage} />
          <span>Te aplicamos un gran descuento por financiar el coche con nosotros</span>
        </div>
        <div className={styles.Icon}>
          <FontAwesomeIcon icon={faChartLine} />
          <span>Sobrevaloramos tu coche si lo entregas como parte de pago</span>
        </div>
      </div>
    </div>
  </div>
);

export default reasons;
