import { Link } from 'react-router-dom';
import { generateCarUrl } from '../../../shared/utility';

import CarCard from '../../../components/UI/CarCard/CarCard';

import styles from './CarList.module.sass';

const carList = (props) => (
  <div className={styles.CarList}>
    <div className={styles.Title}><h2>Últimos coches de ocasión publicados</h2></div>
    <div className={styles.CarListContainer}>
      {props.cars.map((car, index) => <div className={styles.CarContainer} key={index}><Link to={generateCarUrl(car)} key={car._id}><CarCard car={car} width={450}/></Link></div>)}
    </div>
    <div className={styles.Button}><button><a href='/coches-segunda-mano-barcelona'>Coches segunda mano</a></button></div>
    <div className={styles.Delivery}>Entrega a domicilio en 72 horas</div>
  </div>
)

export default carList;
