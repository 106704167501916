import React, { Component } from 'react';

import logo from './logo.svg'

import styles from './Whatsapp.module.sass';

class Whatsapp extends Component {
  render(){
    return (
      <a href="https://wa.me/677279405" className={styles.Whatsapp} target="_blank" rel="noreferrer">
        <img src={logo} alt="Whatsapp logo"/> 
      </a>
    );
  }
}

export default Whatsapp;
