import { Link } from 'react-router-dom';

import styles from './ChangeCar.module.sass';

const changeCar = () => (
  <div className={styles.ChangeCar}>
    <h2 className={styles.Title}>
      ¿Necesitas <span>vender tu coche</span> antes de comprar otro?
    </h2>
    <div className={styles.Subtitle}>
      Si nos entregas tu coche como parte de pago te lo <u>sobrevaloramos</u>
    </div>
    <div className={styles.Boxes}>
      <div className={styles.Box}>
        <div className={styles.Title}>
          Vender coche
        </div>
        <div className={styles.Text}>
          Compramos tu coche al momento, asumimos por ti la garantía y nos ocupamos del papeleo en 30 minutos.
        </div>
        <div className={styles.Button}><button><Link to='/vender-coche-barcelona'>Tasar mi coche</Link></button></div>
      </div>
      <div className={styles.Box}>
        <div className={styles.Title}>
          Gestión de venta
        </div>
        <div className={styles.Text}>
          Puesta a punto y publicación de tu coche totalmente gratis, buscamos comprador y nos ocupamos de todo.
        </div>
        <div className={styles.Button}><button><Link to='/gestion-de-venta'>Saber más</Link></button></div>
      </div>
    </div>
  </div>
);

export default changeCar;
