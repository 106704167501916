import React, { Component } from 'react';
import axios from '../../axios-carways';

import HomeDisclaimer from '../../components/Home/HomeDisclaimer/HomeDisclaimer';
import CarList from '../../components/Home/CarList/CarList';
import LowPrices from '../../components/Home/LowPrices/LowPrices';
import HowItWorks from '../../components/Home/HowItWorks/HowItWorks';
import NoTimeWaste from '../../components/Home/NoTimeWaste/NoTimeWaste';
import ChangeCar from '../../components/Home/ChangeCar/ChangeCar';
import Reasons from '../../components/Home/Reasons/Reasons';
// import Advantages from '../../components/Home/Advantages/Advantages';
// import HomeDelivery from '../../components/Home/HomeDelivery/HomeDelivery';
import Satisfaction from '../../components/Home/Satisfaction/Satisfaction';
import MotivationText from '../../components/Home/MotivationText/MotivationText';
// import BringCar from '../../components/Home/BringCar/BringCar';
import Brands from '../../components/Home/Brands/Brands';
//import WhyDisclaimer from '../../components/Home/WhyDisclaimer/WhyDisclaimer';
//import SmallDisclaimer from '../../components/Home/SmallDisclaimer/SmallDisclaimer';
//import FeaturesIcon from '../../components/Home/FeaturesIcon/FeaturesIcon';
//import FeaturesList from '../../components/Home/FeaturesList/FeaturesList';
//import Map from '../../components/Home/Map/Map';
import Aux from '../../hoc/Aux/Aux';

class Home extends Component{
  state = {
    cars: []
  }

  componentDidMount(){
    this.getCarList();
  }

  getCarList = () => {
    axios.get('/cars?filter=home')
      .then((res) => {
        this.setState({
          cars: res.data
        })
      })
      .catch((err) => {
        this.setState({
          error: 'Error al obtener el listado de coches'
        })
        console.log(err);
      });
  }

  render(){
    return (
      <Aux>
        <HomeDisclaimer />
        <CarList cars={this.state.cars}/>
        <LowPrices />
        <HowItWorks />
        <Reasons />
        <NoTimeWaste />
        <ChangeCar />
        <Brands />
        <Satisfaction />
        <MotivationText />
      </Aux>
    )
  }
}

export default Home;
