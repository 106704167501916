const YEAR_PERCENTAGES = {
  0: 0,
  24: 0.047183,
  36: 0.032767,
  48: 0.025585,
  60: 0.021296,
  72: 0.018454,
  84: 0.016438,
  96: 0.014939,
  108: 0.013784,
  120: 0.012870
}

export const updateObject = (oldObject, updatedProperties) => {
    return {
      ...oldObject,
      ...updatedProperties
    }
}

export const parseParams = (params) => {
  let obj = {}
  params.replace('?', '').split('&').forEach((item, i) => {
    obj = updateObject(
      obj,
      {[item.split('=')[0]]: item.split('=')[1]}
    )
  });
  return obj;
}

export const financingMonthly = (price, carYear, entry = 0, years = 5) => {
  const carYearsOld = new Date().getFullYear() - carYear;
  // console.log('-----');
  // console.log('año del coche -> ', carYear);
  // console.log('años del coche -> ', carYearsOld);
  let discountPercentage = 0.06;
  if(carYearsOld > 5){
    if(price <= 2000){ discountPercentage = 0 };
    if(price > 2000 && price <= 9000){ discountPercentage = 0.02 };
    if(price > 9000 && price <= 15000){ discountPercentage = 0.03 };
    if(price > 15000){ discountPercentage = 0.04 };
  }
  let financing = price - entry;
  // console.log('Precio - entrada: ', financing);
  const discount = financing * discountPercentage;
  // console.log('Descuento: ', discount);
  financing = financing - discount;
  // console.log('A financiar: ', financing);
  let months = years*12;
  if(months > 120){
    months = 120;
  }
  // console.log('Meses: ', months);
  // console.log('Porcentage de tabla por meses: ', YEAR_PERCENTAGES[months]);
  financing = financing * YEAR_PERCENTAGES[months];
  // console.log('Calculo: ', financing);
  return parseInt(financing);
}

export const maxFinancingYears = (year) => {
  return 13 - (new Date().getFullYear() - year);
}

export const numberBeautifier = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const calculateFinalPrice = (car) => {
  let finalPrice = car.price;
  if(car.customOffer.value !== 0){
    finalPrice = finalPrice - car.customOffer.value
  }
  if(car.financingDiscount !== 0){
    finalPrice = finalPrice - car.financingDiscount
  }
  // Always add name change price
  finalPrice = finalPrice + 250;
  return finalPrice
}

// This function is same as calculateFinalPrice but without name change price
export const calculateFinancingPrice = (car) => {
  let finalPrice = car.price;
  if(car.customOffer.value !== 0){
    finalPrice = finalPrice - car.customOffer.value
  }
  if(car.financingDiscount !== 0){
    finalPrice = finalPrice - car.financingDiscount
  }
  return finalPrice
}

export const powerUnit = (car) => {
  let unit = 'CV';
  if(car.sticker === '0'){
    unit = 'kW'
  };
  return unit;
}

export const generateCarUrl = (car) => {
  const brand = car.brand.replaceAll('.', '').replaceAll(' ', '-').toLowerCase();
  const model = car.model.replaceAll('.', '').replaceAll(' ', '-').toLowerCase();
  const url = encodeURIComponent(`${brand}-${model}-${car.year}-${car.kms}-${car.short_id}`);
  return `/coches-segunda-mano-barcelona/${url}`;
}

const deg2rad = (deg) => {
  return deg * (Math.PI/180)
}

export const getDistanceFromLatLonInKm = (lat1,lon1,lat2,lon2) => {
  const r = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2-lat1);  // deg2rad below
  const dLon = deg2rad(lon2-lon1);
  const a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  const d = r * c; // Distance in km
  return d;
}

export const calculateShipphingPrice = (kms) => {
  let price = 1;
  if(kms > 50){ price = 279 };
  if(kms >= 400){ price = kms * 0.70 };
  if(kms >= 450){ price = kms * 0.68 };
  if(kms >= 500){ price = kms * 0.66 };
  if(kms >= 550){ price = kms * 0.64 };
  if(kms >= 600){ price = kms * 0.62 };
  if(kms >= 650){ price = kms * 0.60 };
  if(kms >= 700){ price = kms * 0.58 };
  if(kms >= 750){ price = kms * 0.56 };
  if(kms >= 800){ price = kms * 0.54 };
  if(kms >= 850){ price = kms * 0.52 };
  if(kms >= 900){ price = kms * 0.50 };
  if(kms >= 950){ price = kms * 0.48 };
  if(kms >= 1000){ price = kms * 0.46 };
  if(kms >= 1050){ price = kms * 0.44 };
  if(kms >= 1100){ price = kms * 0.42 };
  if(kms >= 1150){ price = kms * 0.40 };
  if(kms >= 1200){ price = 490 };
  return Math.ceil(price);
}

export const isJson = (text) => {
  try{
    JSON.parse(text)
  }catch(e){
    return false
  }
  return true
}
