import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from '../../../axios-carways';
import Aux from '../../../hoc/Aux/Aux';

import styles from './Satisfaction.module.sass';

class Satisfaction extends Component {
  state = {
    comment: null
  }

  componentDidMount() {
    this.getCommentList();
  }

  getCommentList = () => {
    axios.get('/comments')
      .then((res) => {
        this.setState({
          comment: {...res.data[Math.floor(Math.random() * res.data.length)]}
        });
      })
      .catch((err) => {
        this.setState({
          error: 'Error al obtener el listado de coches'
        })
        console.log(err);
      });
  }

  render(){
    return(
      <div className={styles.Satisfaction}>
        <h2>Opiniones de nuestros clientes</h2>
        <div className={styles.Opinion}>
          {
            this.state.comment && (
              <Aux>
                <div className={styles.LeftColumn}>
                  <LazyLoadImage alt='customerPhoto' src={`${process.env.REACT_APP_API_URL}/comments/${this.state.comment._id}.jpg`} />
                  <div className={styles.Score}>4.8/5</div>
                  <div className={styles.Stars}>
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                  </div>
                  <div className={styles.MoreLink}><a rel='nofollow noreferrer' target='_blank' href='https://google.com/maps/place/Carways+Mobility+-+Coches+de+ocasion+y+segunda+mano/@41.3134109,2.0175253,15z/data=!4m5!3m4!1s0x12a49d93a35904f1:0xd1ad5052169fc6c1!8m2!3d41.3183889!4d2.023526'>Ver más opiniones</a></div>
                </div>
                <div className={styles.RightColumn}>
                  <div className={styles.Name}>{this.state.comment.name}</div>
                  <div className={styles.Comment}>{this.state.comment.comment}</div>
                  <div className={styles.Phrase}>Cuando la pasión se convierte en servicio</div>
                  <div className={styles.Score}>4.8/5</div>
                  <div className={styles.Stars}>
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                  </div>
                  <div className={styles.MoreLink}><a rel='nofollow noreferrer' target='_blank' href='https://google.com/maps/place/Carways+Mobility+-+Coches+de+ocasion+y+segunda+mano/@41.3134109,2.0175253,15z/data=!4m5!3m4!1s0x12a49d93a35904f1:0xd1ad5052169fc6c1!8m2!3d41.3183889!4d2.023526'>Ver más opiniones</a></div>
                  {
                    this.props.showButton && (
                      <div className={styles.Button}><button><a href='/coches-segunda-mano-barcelona'>Comprar coche online</a></button></div>
                    )
                  }
                </div>
              </Aux>
            )
          }
        </div>
      </div>
    )
  }
}

export default Satisfaction;
