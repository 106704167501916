import logo from '../Logo/carways_logo.png';
import styles from './Spinner.module.css';

const spinner = () => (
  <div className={styles.Centered}>
    <img src={logo} alt='Loader img'/>
    <br/>
    Cargando...
  </div>
)

export default spinner;
