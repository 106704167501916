import { LazyLoadImage } from 'react-lazy-load-image-component';

import styles from './MotivationText.module.sass'

import img from './motivation_text.webp';

const motivationText = () => (
  <div className={styles.MotivationText}>
    <div className={styles.Text}>
      Comprar un coche de ocasión es una de las decisiones más importantes de nuestra vida.
      <br /><br />
      Es por eso que nos tomamos muy en serio tu experiencia con nuestro equipo, el concesionario y el coche que compres.
      <br /><br />
      Con el paso de los años hemos ido mejorando, tanto a nivel de servicios como en volumen de ventas, y gran parte de nuestro negocio se nutre de las recomendaciones que vosotros nos dáis.
      <br /><br />
      Queremos estar a la altura y cumplir con tus expectativas, por eso <span>te damos nuestra palabra</span> de que haremos todo lo posible por que así sea.
    </div>
    <div className={styles.Image}><LazyLoadImage src={img} /></div>
  </div>
);

export default motivationText;
