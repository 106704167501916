import React, { Suspense, Component, useEffect } from 'react';
import { withRouter, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';

import * as actions from './store/actions';

import Layout from './hoc/Layout/Layout';
import Home from './containers/Home/Home';

import Spinner from './components/UI/Spinner/Spinner';
import PageLoader from './components/UI/PageLoader/PageLoader';

const LazyList = React.lazy(() => import('./containers/List/List'));
const LazyShow = React.lazy(() => import('./containers/Show/Show'));
const LazyAuth = React.lazy(() => import('./containers/Auth/Auth'));
const LazyLogOut = React.lazy(() => import('./containers/Auth/Logout'));
const LazyListAdminCar = React.lazy(() => import('./containers/Admin/ListCar/ListCar'));
const LazyAddCar = React.lazy(() => import('./containers/Admin/AddCar/AddCar'));
const LazyEditCar = React.lazy(() => import('./containers/Admin/EditCar/EditCar'));
const LazyComments = React.lazy(() => import('./containers/Admin/Comments/Comments'));
const LazyFinancing = React.lazy(() => import('./containers/Financing/Financing'));
const LazySell = React.lazy(() => import('./containers/Sell/Sell'));
const LazyLanding = React.lazy(() => import('./containers/Landing/Landing'));
const LazyImportCar = React.lazy(() => import('./containers/ImportCar/ImportCar'));
const LazyPrivacy = React.lazy(() => import('./containers/Legal/Privacy/Privacy'));
const LazyCookies = React.lazy(() => import('./containers/Legal/Cookies/Cookies'));
const LazyAdvice = React.lazy(() => import('./containers/Legal/Advice/Advice'));
const LazyHistory = React.lazy(() => import('./containers/History/History'));
const LazyWarranty = React.lazy(() => import('./containers/Warranty/Warranty'));
const LazyHowItWorks = React.lazy(() => import('./containers/HowItWorks/HowItWorks'));
const LazySellManagement = React.lazy(() => import('./containers/SellManagement/SellManagement'));
const LazyNotFound = React.lazy(() => import('./containers/NotFound/NotFound'));

const tagManagerArgs = {
    gtmId: 'GTM-N8ZBRFR'
}

TagManager.initialize(tagManagerArgs);

const PrivateRoute = ({ children, isAuth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth ? (
          children
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

const ScrollToTop = () => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action !== 'POP') {
        window.scrollTo(0, 0);
      }
    })
    return () => unlisten();
  }, [])
  return (null);
}

class App extends Component {
  componentDidMount(){
    this.props.onTryAutoSignin();
  }

  render() {
    let layout = <PageLoader />;
    if(this.props.autoAuthDone){
      layout = (
        <Layout location={this.props.location}>
          <Suspense fallback={<Spinner />}>
            <ScrollToTop />
            <Switch>
              <PrivateRoute path='/admin/cars/add' isAuth={this.props.isAuth}>
                <LazyAddCar />
              </PrivateRoute>
              <PrivateRoute path='/admin/cars/edit/:id' isAuth={this.props.isAuth}>
                <LazyEditCar />
              </PrivateRoute>
              <PrivateRoute path='/admin/cars' isAuth={this.props.isAuth}>
                <LazyListAdminCar />
              </PrivateRoute>
              <PrivateRoute path='/admin/comments' isAuth={this.props.isAuth}>
                <LazyComments />
              </PrivateRoute>
              <PrivateRoute path='/logout' isAuth={this.props.isAuth}>
                <LazyLogOut />
              </PrivateRoute>

              <Route path='/segunda-mano/audi' >
                <Redirect to='/audi/segunda-mano-barcelona' />
              </Route>
              <Route path='/segunda-mano/bmw' >
                <Redirect to='/bmw/segunda-mano-barcelona' />
              </Route>
              <Route path='/segunda-mano/mercedes' >
                <Redirect to='/mercedes/segunda-mano-barcelona' />
              </Route>
              <Route path='/segunda-mano/porsche' >
                <Redirect to='/porsche/segunda-mano-barcelona' />
              </Route>
              <Route path='/segunda-mano/volkswagen' >
                <Redirect to='/volkswagen/segunda-mano-barcelona' />
              </Route>
              <Route path='/segunda-mano/:carParams' component={LazyShow}/>
              <Route path='/coches-segunda-mano' >
                <Redirect to='/coches-segunda-mano-barcelona' />
              </Route>
              <Route path='/financiacion' >
                <Redirect to='/financiar-coche' />
              </Route>

              <Route path='/audi/segunda-mano-barcelona' exact component={LazyList} />
              <Route path='/bmw/segunda-mano-barcelona' exact component={LazyList} />
              <Route path='/mercedes/segunda-mano-barcelona' exact component={LazyList} />
              <Route path='/porsche/segunda-mano-barcelona' exact component={LazyList} />
              <Route path='/volkswagen/segunda-mano-barcelona' exact component={LazyList} />
              <Route path='/coches-segunda-mano-barcelona/baratos' component={LazyList} key='coches-segunda-mano-barcelona-baratos'/>
              <Route path='/coches-segunda-mano-barcelona/automaticos' component={LazyList} key='coches-segunda-mano-barcelona-automaticos'/>
              <Route path='/coches-segunda-mano-barcelona/financiados' component={LazyList} key='coches-segunda-mano-barcelona-financiados'/>
              <Route path='/coches-segunda-mano-barcelona/seminuevos' component={LazyList} key='coches-segunda-mano-barcelona-seminuevos'/>
              <Route path='/coches-segunda-mano-barcelona/suv-4x4' component={LazyList} key='coches-segunda-mano-barcelona-suv-4x4'/>
              <Route path='/coches-segunda-mano-barcelona/2500-euros' component={LazyList} key='coches-segunda-mano-barcelona-2500-euros'/>
              <Route path='/coches-segunda-mano-barcelona/:carParams' component={LazyShow}/>
              <Route path='/coches-segunda-mano-barcelona' component={LazyList} key='coches-segunda-mano-barcelona' />
              <Route path='/coches-vendidos' component={LazyList} key='coches-vendidos' />
              <Route path='/coches-importacion-alemania-carta' component={LazyImportCar} />
              <Route path='/gestion-de-venta' component={LazySellManagement} />
              <Route path='/financiar-coche' component={LazyFinancing} />
              <Route path='/vender-coche-barcelona' component={LazySell} />
              <Route path='/vender-mi-coche-barcelona' component={LazyLanding} />
              <Route path='/vender-coche-urgente' component={LazyLanding} />
              <Route path='/vender-coche-reserva-dominio' component={LazyLanding} />
              <Route path='/vender-coche-a-concesionario' component={LazyLanding} />
              <Route path='/compramos-tu-coche' component={LazyLanding} />
              <Route path='/nosotros' component={LazyHistory} />
              <Route path='/por-que-nosotros' component={LazyWarranty} />
              <Route path='/como-funciona' component={LazyHowItWorks} />
              <Route path='/politica-de-privacidad' component={LazyPrivacy} />
              <Route path='/politica-de-cookies' component={LazyCookies} />
              <Route path='/aviso-legal' component={LazyAdvice} />
              <Route path='/admin' component={LazyAuth} />
              <Route path='/' exact component={Home} />
              <Route component={LazyNotFound} />
            </Switch>
          </Suspense>
        </Layout>
      );
    }

    return layout;
  }
}

const mapStateToProps = state => {
  return {
    isAuth: state.auth.token !== null,
    autoAuthDone: state.auth.autoAuthDone
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignin: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
