import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
//import Cx from 'classnames';
import styles from './Footer.module.sass';

const currentYear = (new Date()).getFullYear().toString();

const footer = () => (
  <div className={styles.Footer}>
    <div className={styles.Filter}>
      <div className={styles.Columns}>
        <div className={styles.Column}>
          <div className={styles.Title}>Servicios</div>
          <div className={styles.Link}><a href='/coches-segunda-mano-barcelona'>Coches ocasión Barcelona</a></div>
          <div className={styles.Link}><a href='/vender-coche-barcelona'>Vender coche Barcelona</a></div>
          <div className={styles.Link}><a href='/financiar-coche'>Financiar coche segunda mano</a></div>
          <div className={styles.Link}><a href='/coches-vendidos'>Carways Mobility opiniones</a></div>
        </div>
        <div className={styles.Column}>
          <div className={styles.Title}>Especiales</div>
          <div className={styles.Link}><a href='/coches-segunda-mano-barcelona/baratos'>Coches ocasión baratos</a></div>
          <div className={styles.Link}><a href='/coches-segunda-mano-barcelona/automaticos'>Coches automáticos</a></div>
          <div className={styles.Link}><a href='/coches-segunda-mano-barcelona/financiados'>Coches con financiación</a></div>
          <div className={styles.Link}><a href='/coches-importacion-alemania-carta'>Coches importación Alemania</a></div>
        </div>
        <div className={styles.Column}>
          <div className={styles.Title}>Contacto</div>
          <div className={styles.Link}>
            <FontAwesomeIcon icon={faPhone} />
            <a href='tel:684 46 88 13'>684 46 88 13</a>
          </div>
          <div className={styles.Link}>
            <FontAwesomeIcon icon={faEnvelope} />
            <a href='mailto:info@carwaysmobility.es'>info@carwaysmobility.es</a>
          </div>
          <div className={styles.Link}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <a rel='nofollow noreferrer' href='https://www.google.com/maps/place/Carways+Mobility+-+Coches+de+ocasion+y+segunda+mano/@41.3134109,2.0175253,15z/data=!4m5!3m4!1s0x12a49d93a35904f1:0xd1ad5052169fc6c1!8m2!3d41.3183889!4d2.023526'>
              Carretera de Barcelona 29, 08840, Viladecans (Barcelona)
            </a>
          </div>
          <div className={styles.Link}>
            <a target='_blank' rel='nofollow noreferrer' href='https://facebook.com/carways.mobility'><FontAwesomeIcon className={styles.SocialIcon} icon={faFacebook} /></a>
            <a target='_blank' rel='nofollow noreferrer' href='https://instagram.com/carways_mobility_repair'><FontAwesomeIcon className={styles.SocialIcon} icon={faInstagram} /></a>
          </div>
        </div>
      </div>
      <div className={styles.PolicyLinks}>
        <div className={styles.Year}>2015 - {currentYear} © Carways</div>
        <div className={styles.Link}><a href="/politica-de-privacidad">Política de Privacidad</a></div>
        <div className={styles.Link}><a href="/politica-de-cookies">Política de Cookies</a></div>
        <div className={styles.Link}><a href="/aviso-legal">Aviso Legal</a></div>
        <div className={styles.Link}><a href="/nosotros">Nosotros</a></div>
      </div>
    </div>
  </div>
);

export default footer;
