import styles from './HowItWorks.module.sass';

const howItWorks = () => (
  <div className={styles.HowItWorks}>
    <h2>Compra un coche de segunda mano y quédate tranquilo</h2>
    <div className={styles.Content}>
      <div className={styles.TextContainer}>
        En Carways contamos con más de 6 años de experiencia en el sector de la compra venta de coches de ocasión, más de 1500 coches vendidos y una intachable reputación.
        <br />
        <br />
        Miles de personas han pasado por nuestro concesionario para comprar el coche que estaban buscando, al mejor precio y con los estándares más altos de calidad.
      </div>
      <div className={styles.BoxContainer}>
        <div className={styles.Box}>
          <div className={styles.Title}>¿Te interesa un coche?</div>
          <div className={styles.Text}>
            Ven a vernos a Viladecans y pruébalo en persona
            <br />
            O
            <br />
            <u>Compra tu coche online</u> y recíbelo en tu casa en 72 horas
          </div>
          <div className={styles.Button}><button><a href='/como-funciona'>¿Cómo funciona?</a></button></div>
        </div>
      </div>
    </div>
  </div>
);

export default howItWorks;
