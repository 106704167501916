import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem';
import Cx from 'classnames';
import { Link } from 'react-router-dom';

import logo from '../../UI/Logo/carways_logo.png';
import jeepLogo from '../../UI/Logo/jeep_logo.jpeg';

import styles from './Header.module.sass';

class Header extends Component {
  state = {
    scrolled: false
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      if(window.scrollY > 80){
        this.setState({scrolled: true});
      }else{
        this.setState({scrolled: false});
      }
    });
  }

  closeMenu = () => {
    if(window.innerWidth < 992){
      var evt = document.createEvent("MouseEvents");
      evt.initMouseEvent("click", true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
      let button = document.getElementsByClassName('navbar-toggler')[0];
      button.dispatchEvent(evt);
    }
  }

  render() {
    let menuLinks = (
      <Nav className="m-auto">
        <NavItem className={Cx(styles.LogoDesktop, styles.navItem)}>
          <Link to="/">
            <img src={logo} className={styles.Logo} alt='Logo' title='Logo' />
          </Link>
        </NavItem>
        <NavItem className={styles.navItem}><Link to="/coches-segunda-mano-barcelona" onClick={() => this.closeMenu()}>Coches ocasión</Link></NavItem>
        <NavItem className={styles.navItem}><Link to="/vender-coche-barcelona" onClick={() => this.closeMenu()}>Vender coche</Link></NavItem>
        <NavItem className={styles.navItem}><Link to="/financiar-coche" onClick={() => this.closeMenu()}>Financiar coche</Link></NavItem>
        <NavItem className={styles.navItem}><Link to="/por-que-nosotros" onClick={() => this.closeMenu()}>Por qué nosotros</Link></NavItem>
        <NavItem className={Cx(styles.navItem, styles.LogoDesktop)}>
          <img src={jeepLogo} className={Cx(styles.JeepLogo, styles.JeepLogoDesktop)} alt='jeep logo' title='Jeep logo' />
        </NavItem>
      </Nav>
    )

    if(this.props.isAuth){
        menuLinks = (
          <Nav className="m-auto">
            <NavItem>
              <Link to="/">
                <img src={logo} className={Cx(styles.Logo, styles.LogoDesktop)} alt='Logo' title='Logo' />
              </Link>
            </NavItem>
            <NavItem><Link to="/admin/cars/add">Agregar coche</Link></NavItem>
            <NavItem><Link to="/admin/cars/list">Listar coches</Link></NavItem>
            <NavItem><Link to="/admin/comments">Comentarios</Link></NavItem>
            <NavItem><Link to="/logout">Salir</Link></NavItem>
          </Nav>
        )
    }

    const headerStyle = (this.state.scrolled || this.props.location.pathname !== '/') ? styles.NavbarScrolled : styles.Navbar;

    let stickyStyle = {}
    if(this.props.location.pathname === '/'){
      stickyStyle = {
        sticky: 'top'
      }
    }

    return(
      <Navbar id="header" expand="lg" className={headerStyle} {...stickyStyle}>
        <NavItem className={styles.LogoMobile}>
          <Link to="/">
            <img src={logo} className={styles.Logo} alt='Logo' title='Logo' />
            <img src={jeepLogo} className={Cx(styles.JeepLogo, styles.JeepLogoDesktop)} alt='jeep logo' title='Jeep logo' />
          </Link>
        </NavItem>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {menuLinks}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;
