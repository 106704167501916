import { LazyLoadImage } from 'react-lazy-load-image-component';

import Aux from '../../../hoc/Aux/Aux';

import { financingMonthly, numberBeautifier, maxFinancingYears, calculateFinalPrice, calculateFinancingPrice, powerUnit } from '../../../shared/utility';

import styles from './CarCard.module.sass';

const cardCard = (props) => (
  <div className={styles.CarCard}>
    {
      props.car.sold?
      (
        <div className={styles.Sold}>
          Vendido
        </div>
      )
      :
        props.car.reserved?
        (
          <div className={styles.Reserved}>
            Reservado
          </div>
        )
        :
        null
    }
    <div className={styles.Image}>
      <LazyLoadImage
        src={`${process.env.REACT_APP_API_URL}/cars/${props.car._id}/miniature.webp`}
        alt='car_miniature'
        effect='blur'
      />
    </div>
    <div className={styles.FirstRow}>
      <div>
        <div className={styles.Brand}>
          {props.car.brand}
        </div>
        <div className={styles.Model}>
          {props.car.model}
        </div>
      </div>
      {
        !props.car.sold?
        (
          <div className={styles.Prices}>
            {
              // 190 from name change
              (calculateFinalPrice(props.car)-190 !== props.car.price) ?
                (
                  <Aux>
                    <div className={styles.RedPrice}>
                      <div className={styles.Text}>Precio <br/>al contado</div>
                      <div className={styles.Price}>{`${numberBeautifier(props.car.price)}€`}</div>
                    </div>
                    <div className={styles.BlackPrice}>
                      <div className={styles.Text}>Precio <br/>financiado</div>
                      <div className={styles.Price}>{`${numberBeautifier(calculateFinancingPrice(props.car))}€`}</div>
                    </div>
                  </Aux>
                )
                :
                (
                  <div className={styles.BlackPrice}>
                    <div className={styles.Text}>Precio <br/>al contado</div>
                    <div className={styles.Price}>{`${numberBeautifier(props.car.price)}€`}</div>
                  </div>
                )
            }
          </div>
        )
        :
        null
      }
    </div>
    <div className={styles.SecondRow}>
      <div className={styles.Data}>{`${numberBeautifier(props.car.kms)} Kms | ${props.car.fuel} | ${props.car.year} | ${props.car.power}${powerUnit(props.car)}`}</div>
      {
        !props.car.sold?
        (
          props.showMonthly !== false && maxFinancingYears(props.car.year) > 2 ?
            (
              <div className={styles.MonthlyPrice}>
                <b>{financingMonthly(props.car.price, props.car.year, 0, maxFinancingYears(props.car.year))}€</b>/mes
              </div>
            )
            :
            null
        )
        :
        null
      }
    </div>
  </div>
);

export default cardCard;
