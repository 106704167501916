import logo from '../Logo/carways_logo.png';
import styles from './PageLoader.module.css';

const pageLoader = () => (
  <div className={styles.Centered}>
    <img src={logo} alt='Loader img'/>
  </div>
)

export default pageLoader;
