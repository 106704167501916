import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import styles from './SearchInput.module.css';

const searchInput = (props) => (
  <div className={styles.SearchInput}>
    <FontAwesomeIcon className={styles.Icon} icon={faSearch} />
    <input className={styles.Input} type='text' placeholder='Marca, combustible o cambio' onChange={(e) => props.handleInput(e)} onKeyUp={(e) => props.handleInputEnter(e)}/>
    <button className={styles.Button} onClick={() => props.handleInputEnter('Clicked')}>buscar</button>
  </div>
)

export default searchInput;
