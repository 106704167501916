import Aux from '../../../hoc/Aux/Aux';
/*import FooterDesktop from './FooterDesktop/FooterDesktop';
import FooterMobile from './FooterMobile/FooterMobile';*/
import Footer from './Footer/Footer';
import FooterCampaign from './FooterCampaign/FooterCampaign';

//import styles from './Footer.module.sass';

const footer = (props) => (
  <Aux>
    {
      props.location.search.indexOf('utm_source') === -1 ?
        <Footer />
        :
        <FooterCampaign />
    }
  </Aux>
);

export default footer;
